import React from "react";
import { Row, Col } from "antd";
import loadable from "@loadable/component";
import LpLayout from "../../../components/Layout/lp-layout";
import SEO from "../../../components/seo";
import {
  emailValidationRegExp,
  phoneValidationRegExp,
  formItemTypes,
} from "../../../components/GatedAsset/constants";
import ogImage from "../../../images/global_assets/og-image.png";
import { gatedSectionsv5 } from "../../../data/landing/gated-v2";
import Heading from "../../../components-v2/Base/HeadingBuilder";
import { lineOfBusiness, zipValidationRegExp } from "../../../constants";
// import GatedForm from "../../../components/CustomGated/GatedForm";
// import Image from "../../../components/ImageQuerys/LargeFeaturesImages";
// import GatedCTA from "../../../components/CustomGated/GatedCTA";
// import GatedSpecial from "../../../components/CustomGated/GatedSpecial";
// import GatedSellingPoint from "../../../components/CustomGated/GatedSellingPoint";

const GatedForm = loadable(() =>
  import("../../../components/CustomGated/GatedForm")
);
const Image = loadable(() =>
  import("../../../components/ImageQuerys/LargeFeaturesImages")
);
const GatedCTA = loadable(() =>
  import("../../../components/CustomGated/GatedCTA")
);
const GatedSpecial = loadable(() =>
  import("../../../components/CustomGated/GatedSpecial")
);
const GatedSellingPoint = loadable(() =>
  import("../../../components/CustomGated/GatedSellingPoint")
);

const formDataToSend = ["name", "email", "phone", "company", "get_demo"];
const shortFormDataToSend = ["name", "email", "phone", "company", "get_demo"];
const subtext = "";
const blockList = [
  "Content feature 1",
  "Content feature 2",
  "Content feature 3",
  "Content feature 4",
];

const data = [
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "text",
    label: "First Name",
    name: "firstName",
    rules: [
      {
        required: true,
        message: "Please enter your first name",
        min: 2,
      },
    ],
    placeholder: "Your First Name",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "text",
    label: "Last Name",
    name: "lastName",
    rules: [
      {
        required: true,
        message: "Please enter your last name",
        min: 2,
      },
    ],
    placeholder: "Your Last Name",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "email",
    label: "Email",
    name: "email",
    rules: [
      {
        required: true,
        message: "Please enter a valid email",
        pattern: emailValidationRegExp,
      },
    ],
    placeholder: "e.g. john_doe@mybusiness.com",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "text",
    label: "Business Name",
    name: "company",
    rules: [
      {
        required: true,
        message: "Please enter your business name",
        min: 3,
      },
    ],
    placeholder: "e.g. MyBusiness Inc. ",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "tel",
    label: "Business Phone",
    name: "phone",
    rules: [
      {
        required: true,
        message: "Please enter your phone",
        pattern: phoneValidationRegExp,
      },
    ],
    placeholder: "e.g. (555) 555-1243 ",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: null,
    label: "Zip Code",
    name: "zip",
    rules: [
      {
        required: true,
        message: "Please enter your zip code",
        min: 4,
        pattern: zipValidationRegExp,
      },
      // () => ({
      //   validator(_, value) {
      //     const address = zipCodesMap.get(value);
      //     if (address && address.zip_code) {
      //       return Promise.resolve();
      //     }
      //     return Promise.reject("Please enter a valid zip code");
      //   },
      // }),
    ],
    placeholder: "e.g. 10001",
    visible: true,
  },
  {
    itemType: formItemTypes.CHECKBOX,
    itemSubType: null,
    label: "Yes, I’d like a demo of SpotOn Restaurant point-of-sale.",
    name: "get_demo",
    visible: true,
  },
];

const GatedAssetv1 = () => {
  return (
    <LpLayout phoneNumber="+13035240930" phoneText="(303) 524-0930">
      <SEO
        title="SpotOn Gated Assets | Point-of-Sale E-Book"
        description="Boost efficiency, increase sales, and keep guests coming back with a single point-of-sale solution for in-person orders, Order with Google, and online ordering"
        image={`https://spoton.com/${ogImage}`}
        robotsData="noindex, nofollow"
      />
      <Row className="gate-heading-v1" gutter={[32, 32]}>
        <Col lg={12}>
          <div>
            <Heading
              injectionType={2}
              level={1}
              symbol="."
              className="text-white"
              withBlueSymbol
            >
              How to Elevate the Restaurant Experience and Strengthen Your Brand
            </Heading>

            <p className="gated-intro-para cta-light">
              Delivering a high-quality restaurant experience rooted in tasty
              food, friendly service, and responsive operations is the key to
              your long-term success. Learn how to improve your guest experience
              to deepen relationships, promote growth, and drive long-term
              success.
            </p>
          </div>
        </Col>
        <Col lg={12}>
          <div className="gated-form-cont-v3">
            <GatedForm
              pageTitle="Title of the downloadable content"
              pageSubtext={subtext}
              formTitle="Download your free whitepaper."
              formItems={data}
              formId="4eb8938a-5deb-4106-80ab-7713c5c34a19"
              formDataToSend={formDataToSend}
              shortFormDataToSend={shortFormDataToSend}
              featureTitle="What you get"
              featureSubtext="Fill out the form to get your free download today."
              blockList={blockList}
              topImg="asset-download.png"
              thankYouPath="/gated-assets/restaurant-experience-strengthen-your-brand/thank-you"
              gatedCampaign="restaurant-experience-strengthen-your-brand"
              lineOfBusiness={lineOfBusiness.HOSPITALITY}
            />
          </div>
          <div className="form-bg">
            <Image imageName="Form-Bg.png" />
          </div>
        </Col>
        <Col lg={24}>
          <Image className="banner-text" imageName="banner-text.png" />
        </Col>
      </Row>
      <Row gutter={[32, 32]}>
        <Col lg={24}>
          <GatedSpecial sectionData={gatedSectionsv5} />
        </Col>
        <Col lg={24}>
          <GatedSellingPoint
            mainPoint="Grow and evolve your restaurant brand"
            imageName="gated-selling-c.png"
            subtext="Since the onset of the pandemic, you’ve faced one challenge after another, but with adversity comes new opportunities. Download our whitepaper today and learn how to grow your brand and grow your results by better understanding what your guests want."
          />
        </Col>
      </Row>
      <GatedCTA />
    </LpLayout>
  );
};

export default GatedAssetv1;
